import {
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { defineMessages, useIntl } from 'react-intl';
import { FnUnary, pass } from 'tiinvo';

export interface SearchBarProps extends Omit<StackProps, 'onChange'> {
  value?: string;
  onChange?: FnUnary<string, any>;
}

//#region i18n

const messages = defineMessages({
  searchbarplaceholder: {
    id: `catalog.Search.searchbarplaceholder`,
    defaultMessage: `Cerca l'argomento di tuo interesse`,
  },
});

//#endregion

export const SearchBar: FC<SearchBarProps> = ({
  value = '',
  onChange = pass,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack flex="1 0 auto" {...props}>
      <InputGroup>
        <InputLeftAddon>
          <AiOutlineSearch />
        </InputLeftAddon>
        <Input
          placeholder={formatMessage(messages.searchbarplaceholder)}
          onChange={(event) => onChange(event.target.value)}
          value={value}
        />
      </InputGroup>
    </Stack>
  );
};
