import * as TThesis from 'types/document/Thesis';
import { either } from 'tiinvo';
import * as api from './api';
import * as TSearchRequest from 'types/catalog/SearchRequest';
import { AxiosResponse } from 'axios';

const client = api.create(api.baseURL());

const searchrequest = (params = TSearchRequest.create(), empty = false) =>
  client.get(`/v1/search/homepage${empty ? '/default' : ''}`, {
    params,
  });

const map = ({ data }: AxiosResponse) => {
  const mapped = {
    count: 0,
    items: [],
  };

  mapped.items = data.searchResult.map((el: any) =>
    TThesis.create({ ...el, user: el.users }),
  );
  mapped.count = data.count;

  return either.right(mapped);
};

const searchhandler = api.mapresponse<
  [],
  { count: number; items: TThesis.Thesis[] }
>({
  200: map,
  304: map,
  400: either.left,
  401: either.left,
  404: either.left,
  500: either.left,
});

export const search = api.withstatushandler(searchhandler)(searchrequest);
