import { useQuery } from '@apollo/client';
import HOMEPAGE_SEARCH, {
  HomepageSearch,
} from '../queries/query/search/homepageSearch';
import { useEffect } from 'react';
import { isstring, pipe } from 'tiinvo';
import { useArrayOf, useNum, useStr } from 'use-tiinvo';

const arrayofstrings = useArrayOf(isstring);

export const useHomepageSearch = () => {
  const text = useStr(``);
  const tags = arrayofstrings([]);
  const limit = useNum(10);
  const offset = useNum(0);
  const themes = arrayofstrings([]);
  const query = useQuery<HomepageSearch>(HOMEPAGE_SEARCH, {
    variables: {
      limit: 6,
      offset: 0,
    },
  });
  const data = query.data?.thesis ?? [];
  const reset = pipe(
    limit.reset,
    offset.reset,
    tags.reset,
    text.reset,
    themes.reset,
  );

  useEffect(() => {
    query.refetch({
      limit: limit.value,
      offset: offset.value,
      // uncomment these when the query will accept them
      // tags: tags.value,
      // text: text.value,
      // themes: tags.value,
    });
  }, [text.value, tags.value, themes.value]);

  return {
    data,
    limit,
    offset,
    query,
    reset,
    tags,
    text,
    themes,
  };
};
