import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { NoRecordsFound } from 'app/ui/datavisualization/NoRecordsFound';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { pipe, str } from 'tiinvo';
import { Tag } from 'types/document/Tag';
import { Theme } from 'types/document/Theme';
import { CatalogProps, toggle } from './Catalog.props';
import { EntryList } from './EntryList';
import { SearchBar } from './SearchBar';
import { SearchChecklist } from './SearchChecklist';

export const DesktopCatalog: FC<CatalogProps> = ({
  entries,
  onTagSelect,
  onTextSearch,
  onThemeSelect,
  searchtext,
  selectedtags,
  selectedthemes,
  tags,
  themes,
}) => {
  const handletagselect = toggle(selectedtags);
  const handlethemeselect = toggle(selectedthemes);
  const paddingx = '4';

  return (
    <Grid templateColumns="repeat(12, 1fr)">
      <GridItem colSpan={{ md: 3, lg: 2 }} paddingX={paddingx}>
        <Stack spacing="4">
          <SearchChecklist<Theme>
            elements={themes.sort((a, b) => str.sortasc(a.name, b.name))}
            selected={selectedthemes}
            onChange={onThemeSelect}
            mapid={(el) => el.id}
            maplabel={(el) => el.name}
          >
            <FormattedMessage
              defaultMessage="Temi"
              id="catalog.DesktopCatalog.themes"
            />
          </SearchChecklist>
          {/* <SearchChecklist<Tag>
            elements={tags}
            selected={selectedtags}
            onChange={onTagSelect}
            mapid={(tag) => tag.id}
            maplabel={(tag) => tag.name}
          >
            <FormattedMessage
              defaultMessage="Tags"
              id="catalog.DesktopCatalog.tags"
            />
          </SearchChecklist> */}
        </Stack>
      </GridItem>

      <GridItem colSpan={{ md: 9, lg: 10 }} paddingX={paddingx}>
        <SearchBar
          onChange={onTextSearch}
          marginBottom="10"
          value={searchtext}
        />
        <EntryList
          entries={entries}
          onTagSelect={pipe(handletagselect, onTagSelect)}
          onThemeSelect={pipe(handlethemeselect, onThemeSelect)}
        />
        {!entries.length && <NoRecordsFound />}
      </GridItem>
    </Grid>
  );
};
