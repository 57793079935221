import { Tag } from 'types/document/Tag';
import gql from 'graphql-tag';

export type AllTags = Record<'tags', Tag[]>;

const ALL_TAGS = gql`
  query AllTags {
    tags {
      id
      name
      created_at
      updated_at
    }
  }
`;

export default ALL_TAGS;
