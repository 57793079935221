import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useDisclosure,
  Wrap,
} from '@chakra-ui/react';
import * as TTag from 'types/document/Tag';
import * as TTheme from 'types/document/Theme';
import React, { FC } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiFilter } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { pipe, str } from 'tiinvo';
import { CatalogProps, toggle } from './Catalog.props';
import { EntryList } from './EntryList';
import { SearchBar } from './SearchBar';
import { SearchChecklist } from './SearchChecklist';

export const MobileCatalog: FC<CatalogProps> = ({
  entries,
  onActionClick,
  onTagSelect,
  onTextSearch,
  onThemeSelect,
  searchtext,
  selectedtags,
  selectedthemes,
  tags,
  themes,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleselecttag = toggle(selectedtags);
  const handleselecttheme = toggle(selectedthemes);

  return (
    <>
      <Stack direction="row">
        <Box flex={{ sm: '1 0 auto' }}>
          <SearchBar
            onChange={onTextSearch}
            marginBottom="6"
            value={searchtext}
          />
        </Box>
        <Box>
          <Button
            leftIcon={<BiFilter />}
            onClick={onOpen}
            variant="primaryOutline"
          >
            <FormattedMessage
              defaultMessage="Filtri"
              id="catalog.MobileCatalog.filters.open"
            />
          </Button>
        </Box>
      </Stack>
      {/* <Wrap marginBottom="8" overflow="hidden">
        {selectedtags.map((tag, index) => (
          <Tag
            cursor="pointer"
            onClick={() => onTagSelect(handleselecttag(tag))}
            index={index}
          >
            <TagLeftIcon as={AiOutlineCloseCircle} />
            <TagLabel>#{tags.find(a => a.id === tag)?.name}</TagLabel>
          </Tag>
        ))}
      </Wrap> */}
      <EntryList
        entries={entries}
        // @ts-ignore
        onActionClick={onActionClick}
        onTagSelect={pipe(handleselecttag, onTagSelect)}
        onThemeSelect={pipe(handleselecttheme, onThemeSelect)}
      />

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <FormattedMessage
              defaultMessage="Filtri"
              id="catalog.MobileCatalog.filters.title"
            />
          </DrawerHeader>
          <DrawerBody>
            <SearchChecklist<TTheme.Theme>
              elements={themes.sort((a, b) => str.sortasc(a.name, b.name))}
              selected={selectedthemes}
              mapid={(arg) => arg.id}
              maplabel={(arg) => arg.name}
              onChange={onThemeSelect}
            >
              <FormattedMessage
                defaultMessage="Temi"
                id="catalog.MobileCatalog.themes"
              />
            </SearchChecklist>
            {/* <SearchChecklist<TTag.Tag>
              elements={tags}
              selected={selectedtags}
              mapid={(arg) => arg.id}
              maplabel={(arg) => arg.name}
              onChange={onTagSelect}
            >
              <FormattedMessage
                defaultMessage="Tags"
                id="catalog.MobileCatalog.tags"
              />
            </SearchChecklist> */}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onClose}>
              <FormattedMessage
                defaultMessage="Chiudi"
                id="catalog.MobileCatalog.filters.close"
              />
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
