import { gql } from '@apollo/client';
import { Thesis } from 'types/document/Thesis';

export type HomepageSearch = {
  thesis: Thesis[];
};

const HOMEPAGE_SEARCH = gql`
  query HomepageSearch($limit: Int = 6, $offset: Int = 0) {
    thesis(
      limit: $limit
      offset: $offset
      order_by: { reactions_aggregate: { count: desc }, created_at: desc }
    ) {
      id
      id_professor
      id_user
      tags {
        id_tag
        id
        tag {
          name
        }
      }
      theme {
        name
      }
      title
      updated_at
      cover_image_url
      pdf_url
      slug
      user {
        id
        last_name
        first_name
      }
    }
  }
`;

export default HOMEPAGE_SEARCH;
