import { Thesis } from 'types/document/Thesis';
import * as searchsdk from 'lib/sdk.client/search';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  array,
  bind,
  either,
  isstring,
  obj,
  pass,
  pipe,
  pipeasync,
  toasync,
} from 'tiinvo';
import { useArrayOf, useBool, useNum, useStr } from 'use-tiinvo';

const arrayofstrings = useArrayOf(isstring);

export const useHomepageSearch2 = () => {
  const [data, setdata] = useState<Thesis[]>([]);
  const count = useNum(0);
  const limit = useNum(6);
  const loading = useBool();
  const offset = useNum(0);
  const tags = arrayofstrings([]);
  const text = useStr(``);
  const themes = arrayofstrings([]);
  const isEmptyQuery = [
    tags.value.length,
    themes.value.length,
    Boolean(text.value),
  ].every((val) => !Boolean(val));
  const foldright = array.fromfunctions(
    pipe(obj.mapkey('count'), count.set),
    pipe(obj.mapkey('items'), setdata),
  );
  const httpcall = pipeasync(
    toasync(loading.settrue),
    bind(
      searchsdk.search,
      {
        limit: limit.value,
        offset: offset.value,
        tags: tags.value,
        term: text.value,
        themes: themes.value,
      },
      isEmptyQuery,
    ),
    toasync(either.fold(pass, foldright)),
    toasync(loading.setfalse),
  );

  const debounced = useCallback(debounce(httpcall, 552), [
    tags.value,
    text.value,
    themes.value,
  ]);

  useEffect(() => void debounced(), [tags.value, text.value, themes.value]);
  useEffect(() => void httpcall(), [offset.value, limit.value]);

  useEffect(() => {
    offset.reset();
    limit.reset();
  }, [tags.value, text.value, themes.value]);

  return {
    count,
    data,
    limit,
    loading,
    offset,
    tags,
    text,
    themes,
  };
};
