import { useQuery } from '@apollo/client';
import {
  AllThemesQueryResult,
  GET_ALL_APPROVED_THEMES,
} from '@hasura/queries/query/themes/getAllThemes';

export const useThemes = () => {
  const query = useQuery<AllThemesQueryResult>(GET_ALL_APPROVED_THEMES);

  const items = query.data?.themes ?? [];
  const count = query.data?.themes_aggregate.aggregate.count ?? 0;

  return {
    items,
    count,
  };
};
