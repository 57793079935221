import { array, num, pipe, str } from 'tiinvo';

export interface SearchRequest {
  category?: string;
  tags: string[];
  term: string;
  themes: string[];
  limit: number;
  offset: number;
}

//#region factories

export const create = (
  partial: Partial<SearchRequest> = {},
): SearchRequest => ({
  category: partial.category ?? ``,
  limit: partial.limit ?? 10,
  offset: partial.offset ?? 0,
  tags: partial.tags ?? [],
  term: partial.term ?? ``,
  themes: partial.themes ?? [],
});

//#endregion

//#region mappables

export const mapcategory = (req: SearchRequest) => req.category;
export const maplimit = (req: SearchRequest) => req.limit;
export const mapoffset = (req: SearchRequest) => req.offset;
export const maptags = (req: SearchRequest) => req.tags;
export const mapterm = (req: SearchRequest) => req.term;
export const mapthemes = (req: SearchRequest) => req.themes;

//#endregion

//#region predicates

export const hastags = pipe(maptags, array.notempty);
export const hasthemes = pipe(mapthemes, array.notempty);
export const hasterm = pipe(mapterm, str.trim, str.length, num.greaterthan(0));

//#endregion
