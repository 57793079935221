import type { Theme } from 'types/document/Theme';
import { FnBase, FnUnary, predicate } from 'tiinvo';
import type { Tag } from 'types/document/Tag';
import type { Thesis } from 'types/document/Thesis';
import type { EntryAction } from 'types/catalog/EntryAction';

export interface CatalogProps {
  entries: Thesis[];
  onReset?: FnBase;
  onActionClick?: FnUnary<[action: EntryAction, element: Thesis], any>;
  onTagSelect: FnUnary<string[], any>;
  onTextSearch: FnUnary<string, any>;
  onThemeSelect: FnUnary<string[], any>;
  searchtext: string;
  selectedtags: string[];
  selectedthemes: string[];
  tags: Tag[];
  themes: Theme[];
}

export const toggle = (elements: string[]) => (element: string) =>
  elements.includes(element)
    ? elements.filter(predicate.withdifferentvalue(element))
    : [...elements, element];
