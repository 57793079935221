import { gql } from '@apollo/client';
import { ThemesAggregate } from 'types/document/ThemesAggregate';
import { Theme } from 'types/document/Theme';

export type AllThemesQueryResult = {
  themes: Theme[];
  themes_aggregate: ThemesAggregate;
};

export const createGetQuery = (cacheParam: string) => gql`
  query ${cacheParam}($approved: Boolean = true) {
    themes(order_by: {created_at: desc}, where: {approved: {_eq: $approved}}) {
      approved
      approved_by
      created_at
      id
      name
      updated_at
      approved_by_user {
        id
        username
        first_name
        last_name
      }
      insert_by_user {
        id
        username
        first_name
        last_name
      }
    }
    themes_aggregate(where: {approved: {_eq: $approved}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_APPROVED_THEMES = createGetQuery('GetAllApprovedThemes');
export const GET_ALL_PENDING_THEMES = createGetQuery('GetAllPendingThemes');
