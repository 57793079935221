import { Container, HStack } from '@chakra-ui/react';
import { useAllTags } from '@hasura/hooks/useAllTags';
import { useHomepageSearch } from '@hasura/hooks/useHomepageSearch';
import { useHomepageSearch2 } from '@hasura/hooks/useHomepageSearch2';
import { useThemes } from '@hasura/hooks/useThemes';
import { useAuth } from 'app/AuthProvider';
import { Catalog } from 'app/catalog';
import { Pagination } from 'app/ui/datavisualization/Pagination';
import { Hero } from 'app/ui/hero';
import { FormattedMessage } from 'react-intl';

export default function Home() {
  const searchquery = useHomepageSearch();
  const search = useHomepageSearch2();
  const tagsquery = useAllTags();
  const themesquery = useThemes();
  const { isLoggedIn } = useAuth();

  return (
    <>
      <Hero
        ContentProps={{
          marginX: `auto`,
          minH: {
            base: isLoggedIn ? '48' : '96',
          },
          maxW: 'container.xl',
          w: 'full',
        }}
        title={
          <FormattedMessage
            defaultMessage="La tesi di laurea che fa per tre"
            id="pages.laura.index.hero.title"
          />
        }
        subtitle={
          <FormattedMessage
            defaultMessage="Connettiamo aziende, docenti e studenti"
            id="pages.laura.index.hero.subtitle"
          />
        }
        src="https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
      />
      <Container
        maxW="container.xl"
        minH="76vh"
        marginTop="10"
        paddingBottom="20"
      >
        <Catalog
          entries={search.data ?? []}
          onReset={searchquery.reset}
          onTagSelect={search.tags.set}
          onTextSearch={search.text.set}
          onThemeSelect={search.themes.set}
          searchtext={search.text.value}
          selectedtags={search.tags.value}
          selectedthemes={search.themes.value}
          tags={tagsquery}
          themes={themesquery.items}
        />
        <HStack padding="4" justify={['center', 'flex-end']}>
          <Pagination
            paginated={{
              current: search.offset.value / search.limit.value,
              items: search.data,
              limit: search.limit.value,
              page: [],
              pages: Math.ceil(search.count.value / search.limit.value),
              perpage: [search.limit.value],
            }}
            onPageChange={(arg) => search.offset.set(arg.current * arg.limit)}
          />
        </HStack>
      </Container>
    </>
  );
}
