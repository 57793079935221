import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxGroup,
  Heading,
  Stack,
} from '@chakra-ui/react';
import React, { Children, ReactNode } from 'react';
import { FnUnary, pass } from 'tiinvo';

export interface SearchChecklistProps<T = string>
  extends Omit<BoxProps, 'onChange'> {
  elements?: T[];
  selected?: string[];
  maplabel?: FnUnary<T, string>;
  mapid?: FnUnary<T, string>;
  onChange?: FnUnary<string[], any>;
}

const capitalizefirstletter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function SearchChecklist<T = string>({
  elements = [],
  maplabel = pass as FnUnary<T, string>,
  mapid = pass as FnUnary<T, string>,
  onChange = pass,
  selected = [],
  children,
  ...props
}: SearchChecklistProps<T> & { children?: ReactNode }) {
  return (
    <Box {...props}>
      {Children.count(children) > 0 && (
        <Heading variant="h6">{children}</Heading>
      )}
      <CheckboxGroup colorScheme="primary" onChange={onChange} value={selected}>
        <Stack direction="column">
          {elements.map((el, index) => (
            <Checkbox size="sm" key={index} value={mapid(el)}>
              {capitalizefirstletter(maplabel(el))}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
}
